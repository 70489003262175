.tabsWrapper {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.tabContent {
    position: relative;
}

.tileWrapper {
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
}
.tileheader {
    background-color: var(--primary-color);
    padding: 1rem;
}
.tilecontent {
    padding: 1rem;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.caseNChatWrapper {
    height: 71vh !important;
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
    width: 50%;
}

.chatSequence {
    padding: 0 1rem 0rem 1rem;
}

.chatAuthor {
    font-weight: bold;
    margin: 1rem 0.5rem 0.5rem 0rem;
    font-size: 0.9rem;
}

.chatPlaceholder {
    position: absolute;
    left: 50%;
    right: 50%;
    top: calc(50% + 2rem);
    transform: translate(-50%, -50%);
    width: 50rem;
    text-align: center;
}

.chatCreateCase {
    border-top: 1px lightgrey solid;
    padding: 1rem 0rem 0.4rem 0rem;
    margin: 2rem 0 0.2rem 0;
    width: 100%;
    text-align: center;
}

.caseWrapper {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem 0 0 1rem;
    width: 70%;

    border-top: 1rem solid var(--primary-color);
    height: 100%;
}
.caseHeaderWrapper {
}
.caseList {
    overflow-y: scroll !important;
    padding-right: 1rem;
    height: calc(100% - 4rem);
    overflow: hidden;
    position: relative;
}

.casePlaceholder {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
}

.ticketWrapper {
}

.ticketStatusWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem 1rem 0rem;
    gap: 3rem;
}

.ticketDetails {
    max-height: 10rem;
    overflow: hidden;
}

.statusIcon {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    display: inline-flex;
}

.ticketChartWrapper {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-top: 1rem solid var(--primary-color);
}

.ticketStatsChart {
    height: 20rem !important;
    width: 100%;
}

.diagramHeader {
    background-color: var(--primary-color);
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.wrap {
    border: 2px #f5f5f5 solid;
    background-color: lightgrey;
}

.diagramWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.diagramChart {
    height: 20rem;
    margin-bottom: 1rem;
}

.diagramItem {
    text-align: center;
    padding: 1rem;
}

.resetPasswordModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 30rem;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}

.chatTextBox {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.dashboardWrapperScroll {
    overflow-y: auto;
    height: calc(100vh - 4rem);
}
